import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Col, Container, Image, Row } from 'react-bootstrap';
import DividerParalax from './divider-paralax';
import Delivery from '../../assets/icons/delivery.svg';
import Food from '../../assets/icons/vegan-food.svg';
import Party from '../../assets/icons/champagne-glass.svg';
import Bday from '../../assets/icons/cake.svg';


export default () => {
    const data = useStaticQuery(query);
    const imageSrc = data.image.childImageSharp.fluid.src;

    return (
        <>
            <DividerParalax bg={imageSrc}>
                <Container>
                    <Row className="text-center text-white pt-5">
                        <Col lg={3} className="my-4">
                            <Image src={Delivery} className='usp' alt='delivery' />
                            <h3 className="text-white">Доставки до дома</h3>
                            <p>Бързи, вкусни и прецизни.</p>
                        </Col>
                        <Col lg={3} className="my-4">
                            <Image src={Food} className='usp' alt='lunch' />

                            <h3 className="text-white">Обедно меню</h3>
                            <p>Разнообразни предложения.</p>
                        </Col>
                        <Col lg={3} className="my-4">
                            <Image src={Party} className='usp' alt='party' />
                            <h3 className="text-white">Частни партита и събития</h3>
                            <p>Забавлявайте се с колеги и приятели.</p>
                        </Col>
                        <Col lg={3} className="my-4">
                            <Image src={Bday} className='usp' alt='birthday' />
                            <h3 className="text-white">Рожденни дни</h3>
                            <p>Правим вашия празник по-специален.</p>
                        </Col>
                    </Row>

                </Container>



            </DividerParalax>
        </>
    )
}

const query = graphql`
    query IndexUSP {
        image: file(relativePath: {eq: "index/usp.jpg"}) {
            childImageSharp {
                fluid(quality: 100) {
                    src
                }
            }
        }
    }
`;
